'use strict';

define('vb/private/helpers/rest',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/monitoring/fetchRequestMonitorOptions',
  'vb/private/monitoring/fetchResponseMonitorOptions',
  'vb/private/helpers/abstractRestHelper',
  'urijs/URI',
], (
  Constants,
  Utils,
  Log,
  LogConfig,
  FetchRequestMonitorOptions,
  FetchResponseMonitorOptions,
  AbstractRestHelper,
  URI,
) => {
  const logger = Log.getLogger('/vb/private/helpers/Rest', [
    // Register custom loggers
    {
      name: 'startRest',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.restHelperStart,
    },
    {
      name: 'endRest',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.restHelperEnd,
    },
  ]);

  // BUFP-25534, don't log the Request object, it goofed up Safari requests
  const canLogRequestObject = !Utils.isSafari();

  /**
   * Helper to make a REST call. This class is loosely based on the fetch API
   * proposal (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API).
   *
   * The private methods are in AbstractRestHelper; this class represents the public API of the helper.
   *
   * @see {AbstractRestHelper}
   * @public this is publicly available API
   */
  class Rest extends AbstractRestHelper {
    /**
     * Creates a new reusable Rest instance.
     *
     * The second argument is typically the container in which this method is invoked, like a page, flow, or
     * application. More precisely, if specified, the object should have a string property <i>extensionId</i>
     * indicating the "scope" in which this method is being invoked. The "scope" can be the base application
     * (extensionId equals to 'base') or an extension. If not specified, 'base' is used as default.
     *
     * @param {string} endpointId - the identifier for the endpoint, like 'service1/fetch' or
     *                              'myExtension:service2/create'
     * @param {object} [options] - an optional object
     * @param {string} [options.extensionId] - an optional string to identify the container in which this method is
     *                                         invoked.
     * @returns {Rest}
     * @throws {TypeError} if 'endpointId' is not a string
     */
    static get(endpointId, options = {}) {
      if (typeof endpointId !== 'string') {
        throw new TypeError(`Invalid endpointId for Rest.get(): ${endpointId}`);
      }

      // base class constructor will create an EndpointReference from this
      return new Rest(endpointId, 'vb/private/services/servicesManager', options);
    }

    /**
     * Registers a hook handler to handle predefined hooks while making the fetch call.
     * See the RestHookHandler class for more information.
     *
     * @param restHookHandler The RestHookHandler implementation
     * @returns {AbstractRestHelper}
     */
    hookHandler(restHookHandler) {
      this.handler = restHookHandler;
      return this;
    }

    /**
     * Additional configuration to configure the request. This should be a map and is
     * described as 'init' here:
     * https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
     *
     * This will directly override any defaults from the system (for example headers that could
     * be automatically setup or a body that was set via the body() method could be overridden).
     * To set a body it's preferred the body() method be used.
     *
     * @param initConf
     * @returns {AbstractRestHelper}
     */
    initConfiguration(initConf) {
      this.initConf = initConf;
      return this;
    }

    /**
     * Sets query or path parameters that are configured on the URL, as well as server variables.
     *
     * The key of the map is normally the name of the parameter to whose value is being specified. The exception
     * to this rule is when specifying the value for a server variable: in this case the key of the map must follow the
     * pattern <tt>server:nameOfServerVariable</tt>, like <tt>server:host</tt>.
     *
     * // TODO support multiple parameters of the same type - or just add another API
     * // TODO since that is an odd case and we want to have a nice API for parameters
     *
     * @deprecated Use serverVariables(), pathParameters() and queryParameters() instead.
     * @param parameterMap Map of key to value of parameters
     * @returns {AbstractRestHelper}
     */
    parameters(parameterMap) {
      this._setParams(parameterMap);
      return this;
    }

    /**
     * Sets server variables that are configured on the URL.
     * Calling rest.serverVariables() will override any server variable values
     * previously set using derprecated rest.parameters() method.
     *
     * @param serverVariables Map of key to value of server variables
     * @returns {AbstractRestHelper}
     */
    serverVariables(serverVariables = {}) {
      this._serverVars = serverVariables;
      return this;
    }

    /**
     * Sets path parameters that are configured on the URL.
     * Path parameter values set using this method will take presedance over
     * values set using derprecated rest.parameters() method.
     *
     * @param pathParameters Map of key to value of path parameters
     * @returns {AbstractRestHelper}
     */
    pathParameters(pathParameters = {}) {
      this._pathParams = pathParameters;
      return this;
    }

    /**
     * Sets query parameters that are configured on the URL.
     * Query parameter values set using this method will take presedance over
     * values set using derprecated rest.parameters() method.
     *
     * @param queryParameters Map of key to value of query parameters
     * @returns {AbstractRestHelper}
     */
    queryParameters(queryParameters = {}) {
      this._queryParams = queryParameters;
      return this;
    }

    /**
     * Sets the transformsContext object that is passed into all transforms functions.
     *
     * @param {Object} transformsContext Map of key to value of parameters
     * @returns {AbstractRestHelper}
     */
    transformsContext(transformsContext) {
      this.transformsCtx = transformsContext;
      return this;
    }

    /**
     * Allows a transformation function to be applied before the request has been
     * made. The input should be in the form of a map, where the keys are the names of the
     * transformation functions and the value is the transformation function itself.
     *
     * The first input to the function will be the following:
     *
     * <code>
     * {
     *   url: full url of the request
     *   parameters: path and query parameters (not writable)
     *   initConfig: Map of other configuration passed into the request (see below)
     * }
     * </code>
     *
     * The 'initConfig' exactly matches the 'init' parameter of the request, as described here:
     * https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
     *
     * A transformation function must return the exact same object back or else an error will
     * occur. The object can have different values. For example a transformation function
     * can replace the URL value, but must still return the entire object.
     *
     * This newly formed configuration will then be passed to future transformation functions,
     * with the final result being used for the data fetch directly.
     *
     * A request transformation function can be passed additional information via the
     * 'requestTransformationOptions' method. Here, the options keyed by the same name of the
     * functions will be passed into the transformation function as a map. Thus, the pattern
     * of the function is:
     *
     * <code>
     *   function (configuration, options) { return configuration }
     * </code>
     *
     * Note that in most cases transformation functions can be defined in the service, for this
     * reason the options for those functions can be passed in via 'requestTransformationOptions'.
     *
     * @param transformFunctionMap
     * @returns {AbstractRestHelper}
     */
    requestTransformationFunctions(transformFunctionMap) {
      if (transformFunctionMap) {
        this.transformRequestFuncMap = transformFunctionMap;
      }
      return this;
    }

    /**
     * Options for a request transformation function. If more information needs to be passed into
     * a request transformation function, a map can be provided where the key is the same name of
     * the transformation function and the value is a map. This map will then be passed into the
     * corresponding function when it is called.
     *
     * @param {Object} transformOptionMap
     */
    requestTransformationOptions(transformOptionMap = {}) {
      this.transformRequestOptionsMap = transformOptionMap;
      return this;
    }

    /**
     * Allows a transformation function to be applied after the request has been
     * made. The input should be in the form of a map, where the keys are the names of the
     * transformation functions and the value is the transformation function itself.
     *
     * The first input to the function will be the following:
     *
     * <code>
     * {
     *   response: The response object
     *   body: The body that corresponds to the requested content type
     * }
     * </code>
     *
     * The 'response' exactly matches the the response object as described here:
     * https://developer.mozilla.org/en-US/docs/Web/API/Response
     *
     * A transformation can return data that is then returned as part of the 'fetch' result,
     * contained within the 'transformationResults' property.
     *
     * Note that in most cases transformation functions can be defined in the service, for this
     * reason the options for those functions can be passed in via 'requestTransformationOptions'.
     *
     * @param transformFunctionMap
     * @returns {AbstractRestHelper}
     */
    responseTransformationFunctions(transformFunctionMap) {
      if (transformFunctionMap) {
        this.transformResponseFuncMap = transformFunctionMap;
      }
      return this;
    }

    /**
     * Sets the body on the request. This can either be a string, a JSON object, or any body object
     * as described here:
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Body
     *
     * @param body The body of the request
     * @returns {AbstractRestHelper}
     */
    body(body) {
      if (body) {
        this.bdy = this.stringifyBody(body);
      }
      return this;
    }

    /**
     * optional control for the Response method called to get the body.
     * one of:
     *  arrayBuffer:
     *  blob:
     *  json:
     *  text:
     *  base64:
     *  base64Url:
     *
     * to do:
     *  formData
     * @param format
     * @returns {AbstractRestHelper}
     */
    responseBodyFormat(format) {
      this.resBodyFormat = format;
      return this;
    }

    /**
     * Makes the rest call given the configuration set on this object. Returns a response as
     * described here:
     * https://developer.mozilla.org/en-US/docs/Web/API/Response
     *
     * @returns {Promise<Response>}
     */
    fetch() {
      let restFetchTime;
      let transformsContext;
      let config;
      let message;
      // Best practice, use promise chain consistently rather than error
      // promise Promise constructor
      return Promise.resolve()
        .then(() => (this.handler && this.handler.handlePreFetchHook(this)) || Promise.resolve())
        .then(() => {
          transformsContext = this.transformsCtx || {};
          return this._initFetchRequestMapAndUrl(transformsContext);
        })
        .then(() => {
          // at this point run the transformation functions as provided. Fetch transformsContext here because
          // preFetch hook handler could have updated the rest instance state.
          config = this._executeRequestTransformations(transformsContext);

          // build the request
          // the fetch() spec says GET cannot have a body; for now, remove empty 'body' property, because Edge chokes
          const initConfigBody = config.initConfig.body;
          if (config.initConfig && (initConfigBody === null || initConfigBody === undefined)) {
            delete config.initConfig.body;
          }

          /**
           * NOTE: AbstractRestHelper (Helper) used to encode the entire URL again, here.
           * Removing that, because it takes encoding control completely away from the transforms, which means
           * ultimately, away from the developer.
           * There is some risk that a custom transform, that relied on our post-encoding, would function
           * differently with this change.  They can work around this by using the "body" transform" to encode,
           * if needed.
           *
           * See BUFP-30950 for one justification; path parameters may have already been encoded here,
           * and if we encode/decode here, we could accidentally encode a "/" in a path param, so we need to be
           * more 'intelligent' about how to encode, closer to where we know what's in the original params.
           * We still encode the entire URL, but before AbstractRestHelper even gets the URL (done in Endpoint).
           * The transforms themselves can encode the additional params as they see fit.
           *
           * The built-in rampTransforms do their own encoding, so they should be fine.
           *
           */
          const url = config.url;

          // process the headers just before creating a Request
          config.initConfig.headers = AbstractRestHelper.processFinalHeaders(config.initConfig.headers);

          if (config.initConfig.body && Rest.needsSafariWorkaround()) {
            // dynamically load serviceWorkerManager instead of adding a hard dependency for this workaround
            return Utils.getResource('vbsw/private/serviceWorkerManager')
              // JET-37027: need to preload the local fetch handler here so we can load the OPT's workaround
              // for JET-37027 which overrides browser Request and replaces it with a wrapper
              .then((ServiceWorkerManager) => ServiceWorkerManager.getInstance().getLocalFetchHandler())
              .then(() => new Request(url, config.initConfig));
          }

          return new Request(url, config.initConfig);
        })
        // allow the hook to add modify the request
        .then((request) => (this.handler && this.handler.handleRequestHook(request)) || request)
        .then((request) => {
          logger.startRest('Starting native fetch with parameters:', canLogRequestObject ? request : config.url);
          message = `${this.id} ${canLogRequestObject ? request.url : config.url}`;
          const mo = new FetchRequestMonitorOptions(
            FetchRequestMonitorOptions.SPAN_NAMES.FETCH, message, canLogRequestObject ? request : config.url,
          );
          return this.log.monitor(mo, (finish) => {
            restFetchTime = finish;

            // BUFP-34920: This is a workaround for the issue with safari not supporting extraction of a request body
            // that can be used to create a proxy request. The body is directly attached to the request as originalBody.
            // A local fetchHandler is used to process the request by using the originalBody to create the proxy
            // request.
            // Note: Starting with desktop Safari 14.1 and iOS Safari 14.3, this workaround is no longer necessary.
            if (config.initConfig.body && Rest.needsSafariWorkaround()) {
              // dynamically load serviceWorkerManager instead of adding a hard dependency for this workaround
              return Utils.getResource('vbsw/private/serviceWorkerManager')
                .then((ServiceWorkerManager) => {
                  // We need to instruct the service worker to skip handling this request. Note that we use only
                  // the path portion of the url for request matching because the final request could be proxied.
                  const uri = new URI(request.url);
                  const requestsToSkip = [{ url: uri.path(), method: request.method }];

                  return ServiceWorkerManager.getInstance().addRequestsToSkip(requestsToSkip)
                    .then(() => ServiceWorkerManager.getInstance().getLocalFetchHandler())
                    .then((fetchHandler) => {
                      // only use the VB workaround if there is no offline handler or OPT's workaround
                      // is not available
                      // eslint-disable-next-line no-underscore-dangle
                      if (!fetchHandler.hasOfflineHandler || !request._browserRequest) {
                        request.originalBody = config.initConfig.body;
                      }

                      return fetchHandler.handleRequest(request);
                    })
                    .finally(() => {
                      ServiceWorkerManager.getInstance().removeRequestsToSkip(requestsToSkip);
                    });
                });
            }

            return this._fetchRequest(request);
          })
            .then((rsp) => {
              logger.endRest('Ending native fetch with response', rsp,
                restFetchTime(
                  new FetchResponseMonitorOptions(FetchResponseMonitorOptions.SPAN_NAMES.FETCH, message, rsp),
                ));
              const response = rsp;
              // allow the hook to modify the response
              return (this.handler && this.handler.handleResponseHook(response)) || response;
            });
        })
        .then((rsp) => {
          const response = rsp;
          // if the response is OK, use the passed-in responseBodyFormat.
          // otherwise, just get whatever format it returned (and ignore errors)
          const bodyPromise = (response && response.ok)
            ? AbstractRestHelper.getBody(response, this.resBodyFormat) // OK
            : AbstractRestHelper.getBody(response).catch(() => null); // some error

          // get the body for all stati; server can return a body with any response.
          return bodyPromise.then((body) => {
            // the finalResult - note that if the request fails, we will still resolve with the response
            // as per the fetch contract
            const result = {
              response,
              // capture the body in the return
              body,
            };

            // if the request is in the 200 range...
            if (response && response.ok) {
              // first run the transformation response functions
              const tr = this._executeResponseTransformations(response, body, transformsContext);
              result.transformResults = tr;
              if (tr.body) {
                result.body = tr.body;
              }

              // allow the hook to respond to after the fetch and transformation functions have run
              if (this.handler) {
                // set the transformsContext just so the hook handler can stash it somewhere
                const postFetchHookResult = Object.assign({}, result);
                postFetchHookResult.transformResults = postFetchHookResult.transformResults || {};
                postFetchHookResult.transformResults.transformsContext = transformsContext;
                // guard against hook handler implementations that don't return a Promise
                return Promise.resolve()
                  .then(() => this.handler.handlePostFetchHook(postFetchHookResult)).then(() => postFetchHookResult);
              }
            } else if (this.handler) {
              // allow the hook to respond to error after the fetch; also guard against hook
              // handler implementations that don't return a Promise
              const postFetchErrorHookResult = Object.assign({}, result);
              return Promise.resolve()
                .then(() => this.handler.handlePostFetchErrorHook(result)).then(() => postFetchErrorHookResult);
            }
            return result;
          });
        });
    }

    /**
     * returns the endpoints full URL, with path/query parameters applied
     * url is not encoded
     * will resolve with empty string if no endpoint found
     *
     * example:
     *   AbstractRestHelper.get('svc/endpoint').parameters({foo:'fooby'}).toUrl().then((url) => ...);
     *
     * @returns {Promise<String>}
     *
     */
    toUrl() {
      return this._getEndpoint()
        .then((endpoint) => (endpoint ? endpoint.getConfig(this._allParams, { ignoreMissingParams: true }) : null))
        .then((config) => (config ? config.url : ''))
        .catch((e) => {
          this.log.error('AbstractRestHelper', this.id, 'error fetching full URL for endpoint',
            JSON.stringify(this.endpointReference), e);
          return '';
        });
    }

    /**
     * returns the endpoints relative URL, with path/query parameters applied.
     * this URL does not contain the url of the service.server
     * url is not encoded
     * will resolve with empty string if no endpoint found
     *
     * example:
     *   AbstractRestHelper.get('svc/endpoint').parameters({foo:'fooby'}).toRelativeUrl().then((url) => ...);
     *
     * @returns {Promise<String>}
     */
    toRelativeUrl() {
      return this._getEndpoint()
        .then((endpoint) => (endpoint ? endpoint.getConfig(this._allParams, { ignoreMissingParams: true }) : null))
        .then((config) => (config ? config.urlSuffix : ''))
        .catch((e) => {
          this.log.error('AbstractRestHelper', this.id, 'error fetching relative URL for endpoint',
            JSON.stringify(this.endpointReference), e);
          return '';
        });
    }

    /**
     * Starting with desktop Safari 14.1 and iOS Safari 14.3, we no longer need the workaround for
     * issues regarding retrieving the payload from a request. This code is adapted from OPT for determining
     * if the Safari workaround is needed.
     *
     * @returns {boolean}
     */
    static needsSafariWorkaround() {
      if (this.needsSafariWorkaroundFlag === undefined) {
        // check if it is a safari userAgent and capturing the version
        // this check covers desktops and new versions of ipads
        // user agent is different for older ipads and all iphones
        const isSafari = navigator.userAgent.match(/^(?:(?!chrome|android|iphone|ipad).)*\/([0-9\.]*) safari.*$/i);
        // if there is a match, there is a value otherwise it's null
        if (isSafari) {
          /** @type {string[]} */
          const test = isSafari[1].split('.');
          /** @type {number|void} */
          const test0 = test[0] && parseInt(test[0], 10);
          /** @type {number|void} */
          const test1 = test[1] && parseInt(test[1], 10);
          // Check if safari at least v14.1 since this is when the version with formdata is updated.
          if (test0 > 14) {
            this.needsSafariWorkaroundFlag = false;
          } else if (test0 === 14 && test1 >= 1) {
            this.needsSafariWorkaroundFlag = false;
          } else {
            this.needsSafariWorkaroundFlag = true;
          }
        } else {
          // iOS webkit engine is bundled with the iphone OS version
          // ALL browsers on iOS devices are webkit based
          // ie if broken in safari, will be broken in chrome on iOS device
          const isiOS = navigator.userAgent.match(/(?:iPad|iPhone).*?os ([0-9\_]*)/i);
          // check for iOS version, request + formdata support added in iOS 14_3 + based on browserstack
          if (isiOS) {
            /** @type {string[]} */
            const test = isiOS[1].split('_');
            /** @type {number|void} */
            const test0 = test[0] && parseInt(test[0], 10);
            /** @type {number|void} */
            const test1 = test[1] && parseInt(test[1], 10);
            if (test0 > 14) {
              this.needsSafariWorkaroundFlag = false;
            } else if (test0 === 14 && test1 >= 3) {
              this.needsSafariWorkaroundFlag = false;
            } else {
              this.needsSafariWorkaroundFlag = true;
            }
          } else {
            this.needsSafariWorkaroundFlag = false;
          }
        }
      }
      return this.needsSafariWorkaroundFlag;
    }
  }

  return Rest;
});

