'use strict';

define('vb/private/services/endpoint',[
  'vb/private/log',
  'vb/private/services/uriTemplate',
  'vb/private/services/swaggerUtils',
  'vb/private/utils',
  'vb/private/services/definition/openApiDefinitionObject',
  'vb/private/services/servicesLoader',
  'vb/private/services/endpointMetadata',
  'vb/private/stateManagement/router',
  'vbc/private/constants',
  'vb/private/services/serviceUtils'],
(Log, UriTemplate, SwaggerUtils, Utils, OpenApiDefinitionObject, ServicesLoader, EndpointMetadata,
  Router, CommonConstants, ServiceUtils) => {
  const logger = Log.getLogger('/vb/private/services/endpoint');

  /**
   * Endpoint
   *
   * example access:
   * ServicesManager.getServices().then((services) => {
   *   const endpoint = service.getEndpoint('servicename/endpointname');
   *   ...
   * });
   */

  class Endpoint extends OpenApiDefinitionObject {
    /**
     *
     * @param {Object} options
     * @param {string} options.name
     * @param {Object|ServiceDefinition} options.service service definition or its duck type
     * @param {ProtocolRegistry} options.protocolRegistry
     * @param {string} options.pathKey
     * @param {PathObject} options.pathObject OpenaApi path object
     * @param {string} options.operationKey 'get'|'put'...
     * @param {OperationObject} options.operationObject  OpenaApi operation object
     * @param {boolean} options.isUnrestrictedRelative
     */
    constructor({
      name,
      service,
      protocolRegistry,
      pathKey,
      pathObject, // eslint-disable-line no-unused-vars
      operationKey,
      operationObject,
      isUnrestrictedRelative,
    }) {
      // for now, no catalogInfo
      super(name, operationObject, service, service.namespace, service.relativePath, null, isUnrestrictedRelative);

      this._service = service;
      this._pathKey = pathKey || '';

      this._protocolRegistry = protocolRegistry;

      this.method = (operationKey && operationKey.toUpperCase()) || 'GET';
      this.description = operationObject.description || '';

      // parameters.path, parameters.query, parameters.body, etc.
      // note: header parameters are currently unused; to define a header, use the x-vb.headers extension
      // swagger headers only allow specifying the server default for a value, and not the value the client should use

      // array of parameter definitions (swagger/openapi)
      this.parameterDefs = operationObject.parameters.slice();

      // an object, with parameter definitions separated by type (path, query, etc)
      this.parameters = SwaggerUtils.separateParameters(this.parameterDefs);

      // this EndpointMetadata is not usable until load() has been called, to resolve the url
      this._metadata = new EndpointMetadata(this, operationObject);

      // create a parameter validation method; definitionObject does not currently keep a reference to the
      // pathObject or operationObject, to try to limit the dependencies.

      // this is a merge of the application-level header extensions with the operation header extensions
      // this.headers = Object.assign({}, this._parentExtensions[VB_HEADERS], this._extensions[VB_HEADERS]);
      const openApiHeaders = operationObject.getStaticHeaderValues();

      // no need to merge with parent (Service), this is only valid at the operationId (endpoint) level
      // this.staticQueryParams = this._extensions[VB_STATIC_QUERY_PARAMS] || {};
      const openApiStaticQueryParams = operationObject.getStaticQueryParameterValues();

      // a simple combination of 'info' level extensions, overridden by 'endpoint' extensions.
      // note, this is not a 'deep' merge of inner objects; its just using Object.assign.
      const openApiCombinedExtensions = operationObject.getCombinedExtensions();

      // now merge the ones from the service definition (openApi) with any we might have gotten from the catalog
      const parentExtensions = (this._parent && this._parent.extensions) || {};
      const extensions = this.extensions || {};

      this.staticQueryParams = Object.assign({}, openApiStaticQueryParams,
        parentExtensions.queryParameters || {}, extensions.queryParameters || {});

      // note: this is before catalog resolution; when we load the endpoint,
      // we may get more headers and extensions from the catalog.
      this.headers = Object.assign({}, openApiHeaders, parentExtensions.headers || {}, extensions.headers || {});
      this.combinedExtensions = Object.assign({}, openApiCombinedExtensions,
        parentExtensions, extensions, { headers: this.headers }); // headers are merged
    }

    get path() {
      return this._pathKey;
    }

    /**
     * Used by EndpointMetadata
     */
    get service() {
      return this._service;
    }

    load() {
      // ask the runtime environment for any extension override
      // TODO: ideally, this should be handled by the deployment profile specific for the page designer
      if (!this._loadPromise) {
        this._loadPromise = Utils.getRuntimeEnvironment()
          .then((rtEnv) => rtEnv.getServiceExtensionOverride())
          .then((extOverride) => {
            if (extOverride) {
              this.combinedExtensions = Object.assign(this.combinedExtensions, extOverride);
            }
            return super.load()
              .catch((e) => {
                // Ignoring the error on super because the delayed initialization of endpoints
                // to support dynamic server variables;
                logger.warn('Error while loading parts of the endpoint: ', this.name, ' - ', this.path, e);
                return this;
              });
          });
      }
      return this._loadPromise;
    }

    /**
     * Handles the server variables returning a string with the resolved server url plus the unresolved path
     *
     * @param {object} serverVariables
     * @returns {string} unresolvedUrl
     * @private
     */
    _getPartiallyResolvedUrl(serverVariables = {}) {
      let unresolvedUrl;
      const server = this.service.server;
      if (server) {
        let serverUrl = server.getUrl(serverVariables) || '';
        if (serverUrl[serverUrl.length - 1] === '/') {
          serverUrl = serverUrl.substring(0, serverUrl.length - 1);
        }
        unresolvedUrl = serverUrl + this.path;
      } else {
        unresolvedUrl = this.path;
      }

      return unresolvedUrl;
    }

    /**
     * Computes this endpoint's url and returns the variables to be used when replacing non-server variables.
     *
     * @param {object} requestVariables
     * @returns {Promise<>}
     * @private
     */
    _updateUrl(requestVariables) {
      const serverVariables = requestVariables.serverVariables;
      // url with the resolved server url path plus the unresolved path
      const unresolvedUrl = this._getPartiallyResolvedUrl(serverVariables);
      return this.load()
        .then(() => ServicesLoader.getCatalogExtensions(
          this._protocolRegistry,
          unresolvedUrl,
          this.service.nameForProxy,
          this.namespace,
          serverVariables,
        ))
        .then((catalogInfo) => {
          this.catalogInfo = catalogInfo; // this was set to null in the constructor, update it

          // the original URL, or the resolved url if the protocol was 'vb-catalog'
          this.url = catalogInfo.url || unresolvedUrl;

          // now, merge extension info
          const catalogExtensions = (catalogInfo.backends && catalogInfo.backends.extensions) || {};
          // merge headers, and merge/override the rest.
          // service def extensions take precedence over catalog extensions,
          // and the 'closest' catalog object in the chain has highest precedence.
          // service def and catalog 'headers' are merged with container model declaration (app-flow) 'headers'.
          const catalogBackendHeaders = (catalogExtensions && catalogExtensions.headers) || {};
          const mergedHeaders = Object.assign({}, catalogBackendHeaders, this.combinedExtensions.headers || {});
          this.combinedExtensions = Object.assign({},
            catalogExtensions, this.combinedExtensions, { headers: mergedHeaders });
          this.headers = this.combinedExtensions.headers;

          // create our replacement utility early
          this.uriTemplate = new UriTemplate(this.url, this.parameters);
        });
    }

    /**
     * @typedef Config
     * @property {string} url
     * @property {string} urlSuffix
     * @property {string|Object} method
     * @property {Object} headers
     * @property {string[]} requestContentTypes
     * @property {string[]} responseContentTypes
     */
    /**
     * gets the url, method, and headers defined in the endpoint.
     *
     * @param {Object} requestVariables parameter values
     * @param {Object} [requestVariables.path]
     * @param {Object} [requestVariables.query]
     * @param {Object} [requestVariables.serverVariables]
     * @param {Object} [requestVariables.any]
     * @param options {Object} optional. possible properties:
     *  - ignoreMissingParams: {boolean} if true, URL can have unreplaced templates.
     *                         otherwise, rejects when params are missing
     * @returns {Promise<Config>}
     * @private
     */
    getConfig(requestVariables = {}, options = {}) {
      return this._updateUrl(requestVariables)
        .then(() => {
          if (!this.url || !this.uriTemplate) {
            // this should never happen, this error is to make sure its not even possible
            throw new Error(`getConfig called for endpoint ${this.name} before loading`);
          }

          // check for all required params, and throw an error if any are missing (new to 19.1.1)
          // unless options.ignoreMissingParams is true (used for toUrl()/toRelativeUrl())
          if (!options.ignoreMissingParams) {
            let missingParams = this.uriTemplate.getMissingRequiredParameters(requestVariables);
            // ignore missing query params here: BUFP-32240
            missingParams = missingParams.filter((def) => def.type !== 'query');
            if (missingParams.length) {
              const names = missingParams.map((def) => def.name).join('", "');
              throw new Error(`getConfig() for "${this.name}" is missing required parameters: "${names}"`);
            }
          }

          // uriVariables (passed in) will overwrite static query parameters with the same name
          const staticDefaults = {
            // these staticQueryParams come form x-vb metadata for the endpoint, and
            // they should be overriden by values set via both rest.parameters() and rest.queryParameters()
            query: this.staticQueryParams || {},
          };

          // the ignoreMissingParams is true for Rest.toUrl()/toRelativeUrl(), and makes sure the behavior
          // is the same as it was before BUFP-30950
          let url = this.uriTemplate.replaceRequestParams(
            requestVariables, options.ignoreMissingParams, staticDefaults,
          );

          // The suffix of the url that corresponds to the endpoint path
          const urlSuffix = this.path
            ? new UriTemplate(this.path, this.parameters)
              .replaceRequestParams(requestVariables, options.ignoreMissingParams, staticDefaults)
            : '';

          const headers = this.getAllHeaders(requestVariables);

          // We might need to override the protocol, because you cannot call a http URL from
          // a https page, but we need this workaround for legacy purposes, so we use a header
          // to pass this information onto the service worker
          //
          // bufp-25294
          // @TODO: abstract the use of router, so services may be used without
          // pulling in JET by replacing the implementation of the abstraction.

          const page = Router.getCurrentPage();
          // headers may be modified
          url = ServiceUtils.getHeadersAndUrlForPreprocessing(headers, url, page && !page.isAuthenticationRequired())
           || url;

          // Return configuration
          return {
            url,
            urlSuffix,
            method: this.method,
            headers,
            requestContentTypes: this._metadata.requestContentTypes,
            responseContentTypes: this._metadata.responseContentTypes,
          };
        });
    }

    /**
     * get all headers; includes headers defined by x-vb extension, and parameters defined as in: "header"
     * @param variables
     * @returns {*}
     */
    getAllHeaders(requestVariables = {}) {
      // the pre-defined ones
      const headers = Object.assign({}, this.headers);
      const anyParams = requestVariables.any || {};
      const headerParams = requestVariables.header || {};

      // now check for header parameters
      const headerParamDefs = this.parameters.header || {};
      Object.keys(headerParamDefs).forEach((name) => {
        const headerParamDef = headerParamDefs[name];
        const defaultValue = SwaggerUtils.getParameterDefault(headerParamDef);

        if (defaultValue
          || Object.prototype.hasOwnProperty.call(headerParams, name)
          || Object.prototype.hasOwnProperty.call(anyParams, name)) {
          // replace any existing one
          headers[name] = headerParams[anyParams] || anyParams[name] || defaultValue || '';
        }
      });

      // add the service's proxy and token relay URLs to the vb-info-extension header,
      // in case the "authorization" block indicates we need a proxy or token relay url.
      this.combinedExtensions = ServiceUtils
        .augmentExtension(this.service.nameForProxy, this.catalogInfo, this.combinedExtensions);

      // Pass on the extension information to the service worker.
      headers[CommonConstants.Headers.VB_INFO_EXTENSION] = JSON.stringify(this.combinedExtensions);

      return headers;
    }

    /**
     * map of transforms applied to the request, with disabled transforms filtered
     */
    getRequestTransforms() {
      return this._getFilteredTransforms('request');
    }

    /**
     * map of transforms applied to the response
     */
    getResponseTransforms() {
      return this._getFilteredTransforms('response');
    }

    /**
     * map of transforms relevant to the endpoint. called once before the first request made to an endpoint
     */
    getMetadataTransforms() {
      return this._getFilteredTransforms('metadata');
    }

    /**
     * get a public copy of the endpoint metadata
     * @returns {Promise<EndpointMetadata>}
     */
    getMetadata(expended = true) {
      return Promise.resolve()
        .then(() => (expended ? this._metadata.getExpanded() : this._metadata));
    }

    /**
     * return an object that contains all transforms, except the disabled ones, for a category
     * example syntax:
     * "x-vb": {
            "transforms": {
              "path": ""tests/test-transforms",
              "disabled": {
                "request": ["paginate", "sort", "filter"],
                "response": ["paginate"]
              }
            }
          },
     * @param category 'request', 'response'.
     * @returns {*}
     * @private
     */
    _getFilteredTransforms(category) {
      const disabled = this.disabledTransforms[category] || [];
      // note: this only supports one level of containment; doesn't walk parents
      const parentDisabled = (this._parent && this._parent.disabledTransforms
        && this._parent.disabledTransforms[category]) || [];
      const transforms = Object.assign({}, this.transforms[category]);
      const allDisabled = disabled.slice();
      allDisabled.push(...parentDisabled);
      allDisabled.forEach((name) => {
        delete transforms[name];
      });
      return transforms;
    }

    /**
     * @callback VoidPromise
     * @returns {Promise<void>}
     */
    /**
     * utility method for creating a 'stub' service when creating an Endpoint without a Service context,
     * as we do when creating an Endpoint for catalog.json "paths".
     *
     * Endpoint knows what it needs for a 'parent' service, at a minimum
     * @param name
     * @returns {{catalogInfo: {chain: []}, name: *, nameForProxy: String, load: VoidPromise}}
     */
    static createEmptyService(name) {
      return {
        name,
        nameForProxy: name,
        catalogInfo: {
          chain: [],
        },
        load: () => Promise.resolve(),
      };
    }
  }

  return Endpoint;
});

