'use strict';

define('vbc/private/utils',[
  'vbc/private/constants',
], (Constants) => {
  class Utils {
    /**
     * Generate a string unique Id.
     * @return {string} a unique id
     */
    static generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    }

    /**
     * A very simplistic check to determine whether code is running on SW vs. UI thread
     *
     * @returns {boolean} true, if the code is executing on the service worker thread in the absence of a global
     * window object.
     */
    static isWorkerThread() {
      return globalThis.window === undefined;
    }

    /**
     * Returns true if the value is an object. An array is not counted as an object for this
     * evaluation.
     *
     * @param value The object to test
     * @returns {boolean} True if the object is non-null, defined, and an object
     */
    static isObject(value) {
      return value && typeof value === 'object' && !Array.isArray(value);
    }

    /**
     * Return a promise to load a resource.
     * Reject with the error if there was an error or the file doesn't exist
     *
     * @param  {String} resource the path to the resource to load
     * @returns {Promise} a promise resolving to the content of the resource
     */
    static getResource(resource) {
      return new Promise((resolve, reject) => {
        requirejs([resource],
          (loaded) => {
            resolve(loaded);
          },
          (reason) => {
            reject(reason);
          });
      });
    }

    /**
     * Implements optional chaining.
     * Example:
     * <pre>
     *  const adventurer = {
     *    name: 'Alice',
     *    cat: {
     *      name: 'Dinah'
     *    }
     *  };
     *  const dogName = adventurer.dog?.name;
     *  const alsoDogName = Utils.safelyGet(adventurer, 'dog', 'name');
     *
     * </pre>
     * @param {Object} target
     * @param {string[]} properties
     * @returns {*}
     */
    static safelyGet(target, ...properties) {
      return properties.reduce(
        (currentTarget, propertyName) => currentTarget && currentTarget[propertyName],
        target,
      );
    }

    /**
     * Implements optional chaining when last property is a function.
     *
     * Example:
     * <pre>
     *  const adventurer = {
     *    name: 'Alice',
     *    cat: {
     *      name: 'Dinah'
     *    }
     *  };
     *  const isUndefined = adventurer.cat?.someNonExistentMethod?.();
     *  const isAlsoUndefined = Utils.safelyGet(adventurer, 'cat', 'someNonExistentMethod');
     *
     * </pre>
     * @param {Object} target
     * @param {string[]} properties
     * @returns {*}
     */
    static safelyCall(target, ...properties) {
      // pass back context of the property so we can have correct bindings of the function
      const [context, fn] = properties.reduce(
        ([currContext, currentTarget], // eslint-disable-line no-unused-vars
          propertyName) => [currentTarget, currentTarget && currentTarget[propertyName]],
        [null, target],
      ) || [];
      return fn && fn.apply(context);
    }

    /**
     * Trace options correspond to vbInitConfig.TRACE_CONFIG. If vbInitConfig.TRACE_CONFIG.tracerOptions.applicationId
     * is not specified, vbInitConfig.APP_ID will be used instead.
     * @returns {Object|undefined} trace options extracted from vbInitConfig
     */
    static getTraceOptions(vbInitConfig) {
      if (vbInitConfig && vbInitConfig.TRACE_CONFIG) {
        // eslint-disable-next-line prefer-object-spread
        const tracerOptions = Object.assign({}, vbInitConfig.TRACE_CONFIG.tracerOptions);
        tracerOptions.applicationId = tracerOptions.applicationId || vbInitConfig.APP_ID;
        // eslint-disable-next-line prefer-object-spread
        return Object.assign({}, vbInitConfig.TRACE_CONFIG, { tracerOptions });
      }
      return undefined;
    }

    /**
     * @returns {boolean} a boolean indicating whether the browser is online.
     */
    static isOnline() {
      // if navigator.onLine is false, it is accurate
      if (!navigator.onLine) {
        return false;
      }
      return !(navigator.connection && navigator.connection.type === 'none');
    }

    /**
     * Add a slash to a path if the path is not empty
     * @param {String} path
     * @return {String}
     */
    static addTrailingSlash(path) {
      let newPath = path || '';
      if (newPath && newPath[newPath.length - 1] !== Constants.PATH_SEPARATOR) {
        newPath = `${newPath}/`;
      }

      return newPath;
    }

    /**
     * If present removes a slash at the end of the path
     * @param {String} path
     * @return {String} path without trailing slash
     */
    static removeTrailingSlash(path) {
      return path && path.slice(-1) === '/' ? path.slice(0, -1) : path;
    }

    /**
     * Return a trimmed version of a string or an empty string if the value is not a string
     *
     * @param  {String}  value  The string to clean
     * @return {String}
     */
    static cleanString(value) {
      return (typeof value === 'string' && value.trim()) || '';
    }

    /**
     * Create new Request object for fetching the token relay endpoint.
     *
     * @param {string} tokenRelayUrl URL of the token relay endpoint.
     * @param {boolean} [isTokenRelay2=false] true if the token relay endpoint is new Trap 2.0.
     * @returns {Request}
     */
    static createTokenRelayRequest(tokenRelayUrl, isTokenRelay2) {
      const options = {
        method: 'POST',
        credentials: 'same-origin',
        cache: 'no-cache', // instruct token relay to fetch a fresh token
        headers: {
          [Constants.Headers.USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE]: true,
        },
      };

      if (isTokenRelay2) {
        // new TRAP endpoint expects this grant_type
        const body = {
          grant_type: 'urn:oracle:implicit',
        };
        options.body = JSON.stringify(body);
        options.headers['Content-Type'] = 'application/json';
      }

      return new Request(tokenRelayUrl, options);
    }
  }
  return Utils;
});

