'use strict';

define('vb/private/vx/v2/endpointExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'urijs/URI',
  'vb/private/utils',
  'vbsw/private/constants',
], (BaseExtensionAdapter, URI, Utils, SwConstants) => {
  /**
   * The extension adapter used to load extension from the extension manager using an endpoint URL
   */
  class EndpointExtensionAdapter extends BaseExtensionAdapter {
    initiateFetchManifest() {
      return Promise.resolve().then(() => {
        const requestUrl = this.buildUrl();
        this.log.info('Loading extensions at', requestUrl, 'using extension manager v2.');

        const request = new Request(requestUrl);
        // use offline toolkit to handle caching
        request.headers.set(SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE, 'true');

        return this.getFetchManifestPromise(request)
          .then((results) => {
            const digest = (results && results.digest) || {};
            // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
            const extensions = digest.runtime || [];
            const requirejsInfo = digest['requirejs-info'] || [];
            const appUiInfo = digest['app-ui-info'] || [];
            const pwaInfo = digest['pwa-info'] || [];

            // eslint-disable-next-line object-curly-newline
            const manifest = { extensions, appUiInfo, requirejsInfo, pwaInfo };

            this.log.info('Found', manifest.extensions.length, 'extension(s).');

            return manifest;
          });
      });
    }

    /**
     * Return a URIjs uri object initalized with the registry URL
     *
     * @return {Object}
     */
    getUri() {
      const uri = new URI(this.registryUrl);
      // Ask for runtime, app-ui-info and requirejs-info digests
      uri.search('type=runtime,requirejs-info,app-ui-info,pwa-info');

      return uri;
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v2/digest/[latest|$<sandboxId>]?type=topology
     * @return {String} the URL to fetch the extension manager
     */
    buildUrl() {
      return this.getUri().href();
    }
  }

  return EndpointExtensionAdapter;
});

