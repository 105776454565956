'use strict';

define('vb/private/stateManagement/packageAndExtensionContainerMixin',[
  'vb/private/constants',
  'urijs/URI',
], (Constants, URI) => {
  /**
   * Mixin that should be used by all package container and all extensions
   *
   */
  const PackageAndExtensionContainerMixin = (superclass) => class extends superclass {
    /**
     * Traverse the list of path from the importsCss section of the metadata and build
     * and object with 2 arrays. One array of full URL to CSS and one array of relative CSS.
     * @param  {Array<String>} paths
     * @return {Object}        Object with 3 arrays cssPaths, cssUrls and urlPaths
     */
    resolveCssPaths(paths = []) {
      const results = {
        cssPaths: [], // path to load the potentially bundled css using require
        cssUrls: [], // complete url with protocol to the location of the css
        urlPaths: [], // complete URL with protocol, nothing is done to the css
      };

      paths.forEach((path) => {
        let cssPath = path.trim();
        let cssFolder;
        let cssUrl;
        // When starts with self:, it means extension relative so
        // go to top of extension + ui/self
        if (cssPath.startsWith('self:')) {
          // remove self:
          cssPath = cssPath.substring(5);
          if (cssPath[0] === '/') {
            cssPath = cssPath.substring(1);
          }

          const uiSelf = `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
          cssFolder = `${this.baseUrl}${uiSelf}`;
          cssUrl = `${this.extension.baseUrlDef}/${uiSelf}`;
        } else {
          const uri = URI.parse(cssPath);

          // local url not supported in extensions
          if (!uri.protocol) {
            // absolute url means relative to the App UI
            if (cssPath[0] === '/') {
              cssPath = cssPath.substring(1);
              cssFolder = this.getPackageCssResourceFolder();
              cssUrl = this.getPackageCssResourceUrl();
            } else {
              this.log.error('Cannot load css', cssPath, 'start the path with self:/');
              cssPath = null;
            }
          } else {
            // Loads the url path using the css plugins since relative paths inside the css
            // are relative to the css location.
            results.urlPaths.push(`css!${cssPath}`);
            cssPath = null;
          }
        }

        if (cssPath) {
          // Load the css using the text plugins so we can modify the content
          // When using require css plugin, require injects the css content with <style type="text/css">
          // or a URL reference <link rel="stylesheet" href="...">
          // in the DOM instead of retrieving the content
          results.cssPaths.push(`${cssFolder}${cssPath}`);

          const newCssPath = `${cssUrl}${cssPath}`;
          // Remove the file name from the path and store it in an array for later use
          results.cssUrls.push(newCssPath.substring(0, newCssPath.lastIndexOf('/') + 1));
        }
      });

      return results;
    }

    getPackageCssResourceFolder() {
      return this.package.getResourceFolder();
    }

    getPackageCssResourceUrl() {
      return `${this.extension.baseUrlDef}/${this.resourceLoc}`;
    }
  };

  return PackageAndExtensionContainerMixin;
});

