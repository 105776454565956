'use strict';

define('vb/extensions/dynamic/private/constants',[], () => {
  const Constants = {};

  /**
   * Supported layout types.
   *
   * @type {{CONTAINER: string, COMPONENT: string}}
   */
  Constants.LayoutType = {
    COMPONENT: 'component', // dynamic component
    CONTAINER: 'container', // dynamic container
  };

  /**
   * Constants related to metadata rules.
   * @type {string}
   */
  Constants.METADATA_RULES_FILE = 'metadata-rules';
  Constants.METADATA_RULES_FOLDER = 'metadataRules';

  return Constants;
});

