'use strict';

// NOTE: TrapData.getTrapData().getTokenRelayUrl() is used externally while waiting for the SVC-173060 to be fixed.
// TODO:  remove notes about the SVC-173060 when it gets is fixed.
define('vb/private/services/trapData',[
  'urijs/URI',
  'vb/private/configuration',
  'vb/private/utils',
], (
  URI,
  Configuration,
  Utils,
) => {
  // Names of vbInitConfig properties whose values are used to resolve/build base URL
  const BASE_URL_PARTS = ['VB_SERVER', 'CONTEXT_ROOT', 'APP_URL_PREFIX', 'APP_ID', 'APP_VERSION'];

  // Name of the object containing properties that control configuration of new TRAP services
  // We need an object because future requirenments may include server configuration properties.
  const TRAP_SERVICE_PROPERTY = 'TRAP_SERVICE';
  // Name of vbInitConfig property that turns on new TRAP service support.
  // New service has "trap/2/" string in its path as oppose to old one containing "/services/auth/1.1/".
  const TRAP_SERVICE_ENABLED_PROPERTY = 'ENABLED';
  // Name of local storage property that turns on new TRAP service support.
  const TRAP_SERVICE_ENABLED_STORAGE_NAME = 'vb-trap.enabled';
  // Name of the vbInitConfig property that holds template for the external (RI) TRAP proxy service location
  const TRAP_SERVICE_PROXY_PROPERTY = 'PROXY_URL';
  // Name of the vbInitConfig property that holds template for the external (RI) TRAP tokenRelay service location
  const TRAP_SERVICE_TOKEN_RELAY_PROPERTY = 'TOKEN_RELAY_URL';
  // Name of the vbInitConfig property that holds template for the external (RI) TRAP server list path.
  // The value could be absolute or relative/
  const TRAP_SERVICE_SERVER_LIST_PATH = 'SERVER_LIST_PATH';

  const TRAP_SERVICE_SCOPE_PROPERTY = 'SERVICE_SCOPE';
  const TRAP_SERVICE_AUTH_TYPE_PROPERTY = 'AUTH_TYPE';
  const TRAP_SERVICE_AUTH_TYPE_DEFAULT = 'oauth2_user_assertion';

  // Format of the URL template used to construct base URL for proxy and tokenRelay services
  // APP_ID really means "appId;profile=profileName"
  const VB_PROXY_URL_BASE_PATTERN = '{VB_SERVER}/{CONTEXT_ROOT}/{APP_URL_PREFIX}/{APP_ID}/{APP_VERSION}';

  // placeholder in the URL templates where service/backend name will get injected
  const UNIT_NAME_URL_PART = '{unit_name}';
  // placeholder in the URL templates where endpoint type ('service' or 'backend') will get injected
  const UNIT_TYPE_URL_PART = '{unit_type}';

  // placeholder in the URL templates where extension id will get injected
  const EXTENSION_ID_URL_PART = '{module_id}';
  // placeholder in the URL templates where extension version will get injected
  const EXTENSION_VER_URL_PART = '{module_ver}';

  // Template for the new version of built in TRAP proxy service
  // Example of expanded template (full path):
  //   /ic/builder/rt/{appid}/{appver};profile={profile}/services/trap/2/service/{$serviceName}/proxy/
  const VB_TRAP_PROXY_URL_SERVICE_PATTERN = `services/trap/2/${UNIT_TYPE_URL_PART}/${UNIT_NAME_URL_PART}/proxy/`;
  // Template for the new version of built in TRAP tokenRelay service
  const VB_TRAP_TOKEN_RELAY_URL_SERVICE_PATTERN = `services/trap/2/${UNIT_TYPE_URL_PART}/${UNIT_NAME_URL_PART}/token`;

  // Format of the URL template used to construct base URL for proxy and tokenRelay services on Oracle SAAS Apps is:
  //    {oraSaasApp}/api/{oraSaasTrapServiceName}
  // and the scope is:
  //    urn:opc:resource:fusion:{oraPodName}:{oraSaasTrapServiceName}
  const RIS_TRAP_SERVICE_NAME = 'oraSaasTrapServiceName';
  // defualt value of oraSaasTrapServiceName that can be overridden via global service variables
  const RIS_TRAP_SERVICE_NAME_VALUE = 'rwdinfra';
  const RIS_TRAP_URL_BASE_PATTERN = `{oraSaasApp}/api/{${RIS_TRAP_SERVICE_NAME}}`;
  const RIS_TRAP_SERVICE_SCOPE = `urn:opc:resource:fusion:{oraPodName}:{${RIS_TRAP_SERVICE_NAME}}/`;

  // Template for the RIS TRAP proxy endpoint
  const RIS_TRAP_PROXY_URL_SERVICE_PATTERN = `trap/2/module/${EXTENSION_ID_URL_PART}/${EXTENSION_VER_URL_PART}/${UNIT_TYPE_URL_PART}/${UNIT_NAME_URL_PART}/proxy/`; // eslint-disable-line max-len
  // Template for the RIS TRAP tokenRelay endpoint
  const RIS_TRAP_TOKEN_RELAY_URL_SERVICE_PATTERN = `trap/2/module/${EXTENSION_ID_URL_PART}/${EXTENSION_VER_URL_PART}/${UNIT_TYPE_URL_PART}/${UNIT_NAME_URL_PART}/token`; // eslint-disable-line max-len

  const decodeUrlPattern = (pattern, keys, valueMap, activeProfile) => keys.reduce((currPattern, key) => {
    let tokenValue = valueMap[key];
    // BUFP-30798: include 'profile' as matrix param for proxy/token-relay URLs
    // encode it, just in case, but it shouldn't be necessary
    if (key === 'APP_VERSION' && tokenValue && activeProfile) {
      tokenValue = `${tokenValue};profile=${encodeURIComponent(activeProfile)}`;
    }
    return (tokenValue || BASE_URL_PARTS.includes(key))
      ? currPattern.replaceAll(`{${key}}`, tokenValue || '')
      : currPattern;
  }, pattern);

  const resolveUsingGlobalVariables = (pattern, activeProfile, vbInitConfig) => {
    // first get list of the variable names that are referenced in the URL template
    const parameters = Utils.getTemplateParameterNames(pattern);

    const vars = {};
    // default value(s) go here ---------
    vars[RIS_TRAP_SERVICE_NAME] = RIS_TRAP_SERVICE_NAME_VALUE;
    // -------

    // Same as in ConfigLoader._getServiceGlobalVariables prefer defined params over those from window.vbInitConfig
    parameters.forEach((paramName) => {
      const value = vbInitConfig[paramName];
      if (value) {
        vars[paramName] = value;
      }
    });

    const globalVarsArray = Utils.servicesGlobalVariableSupplier();
    globalVarsArray.forEach((globalVar) => {
      const [varName, value] = globalVar;
      vars[varName] = value;
    });

    return decodeUrlPattern(pattern, parameters, vars, activeProfile);
  };

  const trapInfo = Symbol('trapInfo');

  /**
   * Class respnsible for calculating and caching TRAP service related information.
   *
   * Static methods are exposed for unit testing.
   */
  class TrapData {
    // ---- Start of legacy code from ~4 years ago moved from Utils -------
    /**
     * If for some reason, we didn't get nuggets via vbInitConfig, fall back to using the Configuration.applicationUrl.
     * but we should ALWAYS get nuggets though.
     * @private
     */
    static getFallbackBaseUrlForProxy(vbInitConfig = {}, activeProfile = null) {
      let url = Configuration.applicationUrl || '';

      // The following block of code has this problem:
      //
      // We reached this code because we did not get nuggets (and there is no activeProfile).
      // Even if nuggets are provided as empty strings the code above consider that as "no nuggets".
      // If we did not get nuggets the endOfBase will always be an empty string,
      // since 'APP_ID' and 'APP_VERSION' are missing. That will end up making 'url' be an empty string too,
      // since it goes up to the endOfBase.
      //
      // and remove the end part that we don't need for the token relay/proxy URLs
      // look for the last two parts, just to be safe
      const endOfBase = ['APP_ID', 'APP_VERSION']
        .reduce((acc, cur) => `${acc}${vbInitConfig[cur]}${vbInitConfig[cur] ? '/' : ''}`, '');
      const index = url.indexOf(endOfBase);
      if (index >= 0) {
        url = url.substring(0, index + endOfBase.length);
      }

      // The following block of code has this problem:
      //
      // Again, if we got no nuggets APP_VERSION is not defined and this code will never be executed.
      // If we got any data in vbInitConfig, even just the APP_VERSION; we would have already returned the url value.
      //
      // This code is should find appVersion string in the URL and inject activeProfile after it.
      if (activeProfile && vbInitConfig.APP_VERSION) {
        const verLen = vbInitConfig.APP_VERSION.length;
        const verIndex = url.lastIndexOf(vbInitConfig.APP_VERSION);
        if (verIndex >= 0) {
          url = `${url.substring(0, verIndex)}${vbInitConfig.APP_VERSION}`
            + `;profile=${encodeURIComponent(activeProfile)}${url.substring(verIndex + verLen)}`;
        }
      }
      // same as above; remove the leading from the reduce and concat
      // and replace multiple forward slashes with a single slash (except after ':')
      url = Utils.cleanUpExtraSlashes(url);

      return url;
    }

    /**
     * Returns the 'base' for the proxy/tokenRelay URLs.
     *
     * Use the vbInitConfig parts, so this works for 'regular' and 'vanity url" use cases.
     * examples:
     *
     * https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com/ic/builder/design/myapp/1.0;profile=myprof
     * https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com:443/ic/builder/rt/somewebapp/live/
     *
     * The full path to application's root is VB_SERVER/CONTEXT_ROOT/APP_URL_PREFIX/APP_ID/APP_VERSION/APP_PATH, and
     * base URL for proxy/tokenRelay is VB_SERVER/CONTEXT_ROOT/APP_URL_PREFIX/APP_ID/APP_VERSION;profile=<activeProfile>
     *
     * @param activeProfile
     *
     * @returns {string}
     * @private
     */
    static getBaseUrlForProxyImpl(vbInitConfig = {}, activeProfile = null) {
      // The following block of code has couple of issues:
      // 1. It will cause the method to return "undefined;profile=<activeProfile>" when we don't have any
      //    info provided by the vbInitConfig (aka nuggets) and we have an activeProfile.
      // 2. It does not validate partially provided info by vbInitConfig.
      //    - what happens if we only have app id and version?
      //    - what happens when we have only server url, or
      //    - when we have just app version...
      //
      let url = BASE_URL_PARTS.reduce((acc, curr) => {
        let segment = vbInitConfig[curr];
        // BUFP-30798: include 'profile' as matrix param for proxy/token-relay URLs
        // encode it, just in case, but it shouldn't be necessary
        if (curr === 'APP_VERSION' && activeProfile) {
          segment = `${segment};profile=${encodeURIComponent(activeProfile)}`;
        }
        return `${acc}${Utils.addTrailingSlash(segment)}`;
      }, '');

      if (url && url !== '/////') {
        // remove the leading '/' from the reduce,
        if (url.startsWith('/')) {
          url = url.substring(1);
        }
        // and replace multiple forward slashes with a single slash (except after ':'), and remove ending slash
        return Utils.cleanUpExtraSlashes(url);
      }

      /**
       * if for some reason, we didn't get nuggets, fall back to using the Configuration.applicationUrl.
       * but we should ALWAYS get nuggets though.
       */
      return TrapData.getFallbackBaseUrlForProxy(vbInitConfig, activeProfile);
    }
    // ---- End of legacy code -----

    /**
     * Checks if we should use new TRAP services (VB or remote)
     *
     * @param {Object} vbInitConfig
     * @returns {boolean}
     * @private
     */
    static _shouldUseTrapServiceImpl(vbInitConfig) {
      const localOverride = globalThis.localStorage
       && globalThis.localStorage.getItem(TRAP_SERVICE_ENABLED_STORAGE_NAME);
      if (localOverride === 'true') {
        return true;
      }

      const trapServiceProps = vbInitConfig[TRAP_SERVICE_PROPERTY] || {};

      // VBS-20861: Until RIS TRAP is added (23.01) do not enable TRAP service by default
      /*
      if (Utils.isHostApplication(vbInitConfig)) {
        const proxyUrl = resolveUsingGlobalVariables(RIS_TRAP_URL_BASE_PATTERN, undefined, vbInitConfig);
        const uri = URI.parse(proxyUrl);
        if (uri.protocol === 'http' || uri.protocol === 'https') {
          // we are running in unified app and we can resolve location of the TRAP service so enable RIS TRAP
          // unless TRAP is explicitly disabled
          return trapServiceProps[TRAP_SERVICE_ENABLED_PROPERTY] !== false;
        }
      }
      */
      return trapServiceProps[TRAP_SERVICE_ENABLED_PROPERTY];
    }

    /**
     * Gets the scope value for the Trap service.
     * It defaults to "urn:opc:resource:fusion:<FA pod name>:rwdinfra", but the pattern can be
     * overriden via TRAP_SERVICE.SERVICE_SCOPE value in the vbInitConfig.
     *
     * @param {Object} vbInitConfig
     * @returns {string}
     * @private
     */
    static _getTrapServiceScopeImpl(vbInitConfig) {
      const trapServiceProps = vbInitConfig[TRAP_SERVICE_PROPERTY] || {};
      // just in case provide a way to override scope value
      if (trapServiceProps[TRAP_SERVICE_SCOPE_PROPERTY]) {
        return resolveUsingGlobalVariables(trapServiceProps[TRAP_SERVICE_SCOPE_PROPERTY], undefined, vbInitConfig);
      }
      return resolveUsingGlobalVariables(RIS_TRAP_SERVICE_SCOPE, undefined, vbInitConfig);
    }

    /**
     * Gets the authentication type for the Trap service.
     * It defaults to "oauth2_user_assertion", but the value can be
     * overriden via TRAP_SERVICE.AUTH_TYPE value in the vbInitConfig.
     *
     * @param {Object} vbInitConfig
     * @returns {string}
     * @private
     */
    static _getTrapServiceAuthTypeImpl(vbInitConfig) {
      const trapServiceProps = vbInitConfig[TRAP_SERVICE_PROPERTY] || {};
      // just in case provide a way to override scope value
      if (trapServiceProps[TRAP_SERVICE_AUTH_TYPE_PROPERTY]) {
        return resolveUsingGlobalVariables(trapServiceProps[TRAP_SERVICE_AUTH_TYPE_PROPERTY], undefined, vbInitConfig);
      }
      return TRAP_SERVICE_AUTH_TYPE_DEFAULT;
    }

    /**
     * Detect when TRAP service is a Redwood Infrastracture Service vs. VB built in one
     * @param {string} [activeProfile]
     * @param {Object} vbInitConfig
     * @returns {boolean}
     * @private
     */
    static _isRisTrap(vbInitConfig, activeProfile) {
      let isRisTrap = false;
      if (Utils.isHostApplication(vbInitConfig)) {
        const proxyServiceUrl = resolveUsingGlobalVariables(RIS_TRAP_URL_BASE_PATTERN, activeProfile, vbInitConfig);
        const uri = URI.parse(proxyServiceUrl);
        if (uri.protocol === 'http' || uri.protocol === 'https') {
          // we are running in unified app and we can resolve location of the TRAP service
          isRisTrap = true;
        }
      }
      return isRisTrap;
    }

    /**
     *
     * @param {string} activeProfile
     * @param {boolean} forTokenRelayUrl
     * @param {Object} vbInitConfig
     * @param {boolean} defaultTrap Indicates that TRAP Url should be using default VB TRAP endpoint,
     * @returns {string}
     * @private
     */
    static _getTrapServiceUrlPatternImpl(activeProfile, forTokenRelayUrl, vbInitConfig, defaultTrap) {
      // first find the URL pattern we need to create
      const trapServiceProps = vbInitConfig[TRAP_SERVICE_PROPERTY] || {};

      // Should defaultTrap flag trump these vbInitConfig.TRAP_SERVICE.TOKEN_RELAY_URL and
      // vbInitConfig.TRAP_SERVICE.PROXY_URL patterns too?

      const externalPattern = forTokenRelayUrl
        ? trapServiceProps[TRAP_SERVICE_TOKEN_RELAY_PROPERTY]
        : trapServiceProps[TRAP_SERVICE_PROXY_PROPERTY];

      // location of the TRAP service is provided by the app config via vbInitConfig
      if (externalPattern) {
        // replace the tokens with values from vbInitConfig and services.global initParams
        const externalServiceUrl = resolveUsingGlobalVariables(externalPattern, activeProfile, vbInitConfig);
        // remove any slashes that are next to each other
        return Utils.cleanUpExtraSlashes(externalServiceUrl, true);
      }

      // First get the base URL that is shared for both proxy and tokenRelay
      let baseUrl;
      // detect when TRAP service is a Redwood Infrastracture Service vs. VB built in one
      // eslint-disable-next-line no-underscore-dangle
      const isRisTrap = !defaultTrap && TrapData._isRisTrap(vbInitConfig, activeProfile);
      if (isRisTrap) {
        baseUrl = resolveUsingGlobalVariables(RIS_TRAP_URL_BASE_PATTERN, activeProfile, vbInitConfig);
      }

      // Since the configuration for external TRAP service is missing, or it is not unified app
      // we default to VB built in one
      if (!baseUrl) {
        baseUrl = resolveUsingGlobalVariables(VB_PROXY_URL_BASE_PATTERN, activeProfile, vbInitConfig);
      }

      if (baseUrl && baseUrl !== '////') {
        // remove the leading '/' from the reduce,
        if (baseUrl.startsWith('/')) {
          baseUrl = baseUrl.substring(1);
        }
        // and replace multiple forward slashes with a single slash (except after ':'), and remove ending slash
        baseUrl = Utils.cleanUpExtraSlashes(baseUrl);
      } else {
        // What to do if we do not have nuggets. Fallback the same way as old code.

        baseUrl = TrapData.getFallbackBaseUrlForProxy(vbInitConfig, activeProfile);

        // if we set the URL to an empty string, reset it to the applicationUrl
        baseUrl = baseUrl || Configuration.applicationUrl || '';

        // same as above; remove the leading from the reduce and concat
        // and replace multiple forward slashes with a single slash (except after ':'), and remove ending slash
        baseUrl = Utils.cleanUpExtraSlashes(baseUrl);
      }

      let trapUrl;
      if (isRisTrap) {
        trapUrl = forTokenRelayUrl ? RIS_TRAP_TOKEN_RELAY_URL_SERVICE_PATTERN : RIS_TRAP_PROXY_URL_SERVICE_PATTERN;
      } else {
        trapUrl = forTokenRelayUrl ? VB_TRAP_TOKEN_RELAY_URL_SERVICE_PATTERN : VB_TRAP_PROXY_URL_SERVICE_PATTERN;
      }

      // concatanate proxy/tokenRelay specific ending of the TRAP URL
      return `${baseUrl}/${trapUrl}`;
    }

    constructor(vbInitConfig = {}) {
      this._vbInitConfig = vbInitConfig;
    }

    /**
     * Check if TRAP requests are using Trap 2.0.
     *
     * @returns {boolean}
     */
    shouldUseTrapService() {
      if (this._shouldUseTrapService === undefined) {
        // eslint-disable-next-line no-underscore-dangle
        this._shouldUseTrapService = TrapData._shouldUseTrapServiceImpl(this._vbInitConfig);
      }
      return this._shouldUseTrapService;
    }

    getTrapServiceScope() {
      if (this._trapServiceScope === undefined) {
        // eslint-disable-next-line no-underscore-dangle
        this._trapServiceScope = TrapData._getTrapServiceScopeImpl(this._vbInitConfig);
      }
      return this._trapServiceScope;
    }

    getTrapServiceAuthType() {
      if (this._trapServiceAuthType === undefined) {
        // eslint-disable-next-line no-underscore-dangle
        this._trapServiceAuthType = TrapData._getTrapServiceAuthTypeImpl(this._vbInitConfig);
      }
      return this._trapServiceAuthType;
    }

    /**
     * Gets an URL from which to fetch server overrides specific to the TRAP service.
     *
     * @returns {string}
     */
    getTrapServiceServersUrl() {
      if (this.shouldUseTrapService()) {
        // detect when TRAP service is a Redwood Infrastracture Service vs. VB built in one
        // eslint-disable-next-line no-underscore-dangle
        const isRisTrap = TrapData._isRisTrap(this._vbInitConfig);
        if (isRisTrap) {
          // first find the URL pattern we need to create
          const trapServiceProps = this._vbInitConfig[TRAP_SERVICE_PROPERTY] || {};

          // First get the RIS service URL
          const baseUrl = resolveUsingGlobalVariables(RIS_TRAP_URL_BASE_PATTERN, undefined, this._vbInitConfig);

          // endpoint that provides TRAP service local server list
          let serversUrl;

          const serverListPath = trapServiceProps[TRAP_SERVICE_SERVER_LIST_PATH];
          if (serverListPath) {
            let path;
            if (Utils.isAbsoluteUrl(serverListPath)) {
              path = serverListPath;
            } else if (serverListPath[0] === '/') {
              path = `${baseUrl}${serverListPath}`;
            } else {
              path = `${baseUrl}/trap/2/scene/server/${serverListPath}`;
            }
            const uri = new URI(path);
            serversUrl = uri.normalizePath().toString();
          } else {
            serversUrl = `${baseUrl}/trap/2/scene/server`;
          }

          return serversUrl;
        }
      }
      return null;
    }

    /**
     *
     * @param {string} [activeProfile = null]
     * @param {boolean} [forTokenRelayUrl = false]
     * @param {boolean} [defaultTrap = false] Indicates that TRAP Url should be using default VB TRAP endpoint,
     * @returns {string}
     * @private
     */
    _getTrapServiceUrlPattern(activeProfile, forTokenRelayUrl = false, defaultTrap) {
      const trapData = this._trapData || (this._trapData = {});
      const propertyName = `${forTokenRelayUrl ? 'tokenRelayUrl' : 'proxyUrl'}:${defaultTrap ? '-default' : ''}`;
      const trapDataForProp = trapData[propertyName] || (trapData[propertyName] = {});
      if (!trapDataForProp[activeProfile || '']) {
        // eslint-disable-next-line no-underscore-dangle
        trapDataForProp[activeProfile || ''] = TrapData
          ._getTrapServiceUrlPatternImpl(activeProfile, forTokenRelayUrl, this._vbInitConfig, defaultTrap);
      }
      return trapDataForProp[activeProfile || ''];
    }

    /**
     * @param {Object} options
     * @param {string} [options.activeProfile = null] Active profile if available
     * @param {boolean} [forTokenRelayUrl = false] True for tokenRelay URL, otherwise proxy URL is returned.
     * @param {boolean} [defaultTrap = false] Indicates that TRAP Url should be using default VB TRAP endpoint,
     * @returns {string}
     * @private
     */
    _getTrapUrlPattern({ activeProfile }, forTokenRelayUrl = false, defaultTrap = false) {
      if (this.shouldUseTrapService()) {
        return this._getTrapServiceUrlPattern(activeProfile, forTokenRelayUrl, defaultTrap);
      }

      // Old proxy/tokenRelay VB URL
      const baseUrl = TrapData.getBaseUrlForProxyImpl(this._vbInitConfig, activeProfile);
      if (forTokenRelayUrl) {
        return `${baseUrl}/services/auth/1.1/tokenrelay/${UNIT_NAME_URL_PART}`;
      }
      return `${baseUrl}/services/auth/1.1/proxy/${UNIT_NAME_URL_PART}/uri/`;
    }

    /**
     * Gets the URL for TRAP service endpoint for the given service name.
     *
     * @param {Object} options
     * @param {string} options.serviceName Can be ommitted if unit name is specified.
     * @param {string} options.name unit name, e.g. name of a service or a backend.
     *                              Can be ommited if serviceName is specified.
     * @param {string} [options.type='service']  unit type: 'service'|'backend'
     * @param {string} [options.extensionId = 'base'] module Id
     * @param {string} [options.extensionVersion = '1.0'] module version
     * @param {string} [options.activeProfile = null] Active profile if available
     * @param {boolean} [options.defaultTrap = false] Indicates that TRAP Url should be using default VB TRAP endpoint,
     *                                               and not the RIS one
     * @param {boolean} [forTokenRelayUrl = false] True for tokenRelay URL, otherwise proxy URL is returned.
     * @returns {string}
     * @private
     */
    _getTrapUrl(options, forTokenRelayUrl = false) {
      const {
        extensionId: moduleId = 'base',
        extensionVersion: moduleVer = '1.0',
        type: unitType = 'service', // if type is not specified default is 'service'
        defaultTrap,
      } = options;
      // we need to keep supporting "serviceName", it is part of the public API (see SVC-173060)
      const unitName = options.serviceName || options.name;
      let resolvedPattern = this._getTrapUrlPattern(options, forTokenRelayUrl, defaultTrap)
        .replaceAll(UNIT_NAME_URL_PART, unitName)
        .replaceAll(UNIT_TYPE_URL_PART, unitType)
        .replaceAll(EXTENSION_ID_URL_PART, moduleId)
        .replaceAll(EXTENSION_VER_URL_PART, moduleVer);

      // remove any unresolved tokens within the template
      const unresolvedParameters = Utils.getTemplateParameterNames(resolvedPattern);
      resolvedPattern = unresolvedParameters
        .reduce((currPattern, key) => currPattern.replaceAll(`{${key}}`, ''), resolvedPattern);

      // cleanUpExtraSlashes and keep ending '/'
      return Utils.cleanUpExtraSlashes(resolvedPattern, true);
    }

    // NOTE: TrapData.getTrapData().getTokenRelayUrl() is used externally while waiting for the SVC-173060 to be fixed.
    getTokenRelayUrl(options) {
      return this._getTrapUrl(options, true);
    }

    getProxyUrl(options) {
      return this._getTrapUrl(options, false);
    }
  }

  /**
   * Gets the data stracture to store resolved trap URLs and other caclulated data.
   * The data is stored in globalThis.vbInitConfig.TRAP_SERVICE_PROPERTY.@trapInfo.
   * @param {Object} vbInitConfig
   * @returns {Object}
   */
  // NOTE: TrapData.getTrapData().getTokenRelayUrl() is used externally while waiting for the SVC-173060 to be fixed.
  TrapData.getTrapData = (vbInitConfig = (globalThis.vbInitConfig || {})) => {
    // eslint-disable-next-line no-param-reassign
    const trapConfigProperties = vbInitConfig[TRAP_SERVICE_PROPERTY] || (vbInitConfig[TRAP_SERVICE_PROPERTY] = {});
    if (!trapConfigProperties[trapInfo]) {
      // eslint-disable-next-line no-param-reassign
      trapConfigProperties[trapInfo] = new TrapData(vbInitConfig);
    }
    return trapConfigProperties[trapInfo];
  };

  return TrapData;
});

