'use strict';

define('vb/private/services/transformsUtils',[
  'vb/private/utils',
  'vb/services/transforms/serviceTransformsConstants'],
(Utils, TransformsConstants) => {
  const REQUEST_TRANSFORMS_TYPE_BODY = 'body';
  const REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS = 'fetchByKeys';

  class TransformsUtils {
    /**
     * sort callback that sorts 'body' transform last
     * @param a
     * @param b
     * @returns {number}
     */
    static bodyTransformLast(a, b) {
      if (a === REQUEST_TRANSFORMS_TYPE_BODY) {
        return 1;
      }
      if (b === REQUEST_TRANSFORMS_TYPE_BODY) {
        return -1;
      }
      return 0;
    }

    /**
     * filter callback function to exclude fetchByKeys
     * @param a
     * @returns {boolean}
     */
    static excludeFetchByKeys(a) {
      return (a !== REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS);
    }

    /**
     * sort callback that sorts select transform to be first in list of transforms
     * @param a
     * @returns {number}
     */
    static selectTransformFirst(a) {
      return (a === 'select' ? -1 : 1);
    }

    /**
     * returns a new Object literal with the just the props picked.
     * @param o plain old object or an instance
     * @param props list of props to pick
     * @returns {Object}
     */
    static pick(o, ...props) {
      // @ts-ignore
      return Object.assign({}, ...props.map((prop) => (Object.hasOwn(o, prop) && { [prop]: o[prop] })));
    }

    /**
     * clones a Set. Does not pick properties on Set because today only Set of keys are passed in, where each key
     * is a primitive, Object, or Array
     * @param originalSet
     * @returns {Set<any>}
     */
    static cloneSet(originalSet) {
      return new Set(Array.from(originalSet));
    }

    /**
     * Recursively evaluate value to extract supported properties for fetchParameters and clone. Object literals and
     * arrays are cloned as is; For first class instances only supported properties are extracted and a new Object
     * literal created.
     *
     * @param value the value to evaluate
     * @returns {*}
     */
    static pickAndClone(prop, value) {
      if (!value) {
        return value;
      }

      if (Array.isArray(value)) {
        return value.map((item) => TransformsUtils.pickAndClone(prop, item));
      }

      if (value instanceof Set) {
        // usually just the keys are provided as a Set and so all properties are cloned
        return (TransformsUtils.cloneSet(value));
      }

      if (Utils.isObject(value)) {
        return TransformsUtils.pickAndCloneObject(prop, value);
      }

      return value;
    }

    static pickAndCloneObject(prop, value) {
      const fetchParamPropsDef = TransformsConstants[prop] || {};
      const { properties, propertyTypes } = fetchParamPropsDef;
      // some top level params are type 'any' (example 'filterCriterion.value'. In such cases return just the cloned
      // value via Utils.cloneObject
      if (properties && properties.length > 0) {
        const objValue = TransformsUtils.pick(value, ...properties);
        Object.keys(objValue).forEach((key) => {
          const keyProp = propertyTypes && propertyTypes[key];
          objValue[key] = TransformsUtils.pickAndClone(keyProp, objValue[key]);
        });
        return objValue;
      }
      return Utils.cloneObject(value);
    }

    /**
     * While fetchParameters are what the caller provides via the fetch call, transforms authors can sometimes mutate
     * these. While cloning fetchParameters might be an obvious solution, when the parameter is a reference to a first
     * class instance, cloning fails. This method extracts key properties for each type of fetchParameter and clones
     * the same, to build the final fetchParameters for transforms.
     * @param config the configuration object for the current fetch
     * @returns {{fetchParameters}|*} a cloned pruned fetchParameters
     */
    // eslint-disable-next-line class-methods-use-this
    static extractFetchParametersForTransforms(config) {
      const { capability, fetchParameters } = config;
      const newFetchParams = {};

      if (capability && fetchParameters) {
        const fetchParamsTypeName = TransformsConstants.FetchParamTypes[capability];
        const fetchParamNames = (fetchParamsTypeName && TransformsConstants[fetchParamsTypeName]) || [];
        fetchParamNames.forEach((fpName) => {
          const fpCloned = TransformsUtils.pickAndClone(fpName, fetchParameters[fpName]);
          if (fpCloned !== undefined) {
            newFetchParams[fpName] = fpCloned;
          }
        });
        // all fetch params outside of the known transforms types are passed through as is. Example an SDP that
        // delegates to a RestAction or a RestAction by itself can define its own transforms types (and where
        // author calls rest.setFetchConfiguration). More likely for the former case to happen.
        return Object.assign(config, { fetchParameters: newFetchParams });
      }

      return config;
    }

    static extractOptionsForTransforms(transformsOptions = {}) {
      const newOptions = {};

      if (Object.keys(transformsOptions).length > 0) {
        const toParamNames = TransformsConstants.TransformsOptionsForFetchCommon || [];
        toParamNames.forEach((toName) => {
          const fpCloned = TransformsUtils.pickAndClone(toName, transformsOptions[toName]);
          if (fpCloned !== undefined) {
            newOptions[toName] = fpCloned;
          }
        });
      }
      // all transforms options outside of known transforms types are passed through as is.
      return Object.assign({}, transformsOptions, newOptions);
    }

    /**
     * Checks if any of the transforms needs access to the endpoint responses metadata.
     * By default we assume it does not need it.
     * @returns {boolean}
     */
    static transformsUseResponsesMetadata(transformsMetadataFuncs, configuration) {
      if (transformsMetadataFuncs && transformsMetadataFuncs.requirements) {
        const requirements = transformsMetadataFuncs.requirements.call(null, configuration);
        if (requirements && requirements.usesResponsesMetadata === false) {
          return false;
        }
      }
      return true;
    }
  }

  return TransformsUtils;
});

