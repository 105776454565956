'use strict';

define('vb/private/stateManagement/packageFragment',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/private/stateManagement/container',
  'vb/private/stateManagement/fragment',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
], (Constants, Utils, Mixin, Container, Fragment, PackageContainerMixin, PackageAndExtensionContainerMixin) => {
  /**
   * whether the fragment can be referenced from another extension
   * @type {{EXTENSION: string, SELF: string}}
   */
  const REFERENCEABLE = {
    EXTENSION: 'extension',
    SELF: 'self',
  };

  /**
   * PackageFragment class
   * A class representing a fragment that is referenced in an App UI page / fragment. The fragment itself can be
   * defined in the current extension, or can reference a fragment defined in base app.
   */
  class PackageFragment extends Mixin(Fragment).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, className = 'PackageFragment', name, params, templates) {
      super(id, parent, className, name, params, templates);
      /**
       * tells us which extension defines the fragment. This is set when the name of the fragment references a
       * fragment defined in another extension
       * @type {string}
       */
      this.fragmentExtensionId = undefined;

      // super would have set this.fragmentName but extract the extension part
      if (this.fragmentName) {
        const parsed = Utils.parseQualifiedIdentifier(this.fragmentName, { prefixToken: ':', suffixToken: '' });
        const fName = parsed.main;
        const extId = parsed.prefix;

        if (extId) {
          this.fragmentExtensionId = extId;
          this.fragmentName = fName;
        }
      }

      // determine the extension context the fragment was called under.
      /**
       * Fragments pose an interesting problem when determining the extension to use for a particular fragment. Both
       * page and fragment can include other fragments defined in the same extension or some other extension
       *
       * In extension v2, let's say ExtA and ExtB have the following fragments and page
       * ---------------------------------------------------------------------------------------------------------------
       *   ExtA:                  ExtB:                                       ExtC:
       * ---------------------------------------------------------------------------------------------------------------
       *  /fragments
       *  - fragA1                - fragB1                                    - fragC1
       *                              <uses> ExtA:fragA1
       *                              <uses> fragB2
       *                          - fragB2
       *  /pages
       *                          - pageB1                                    - pageC1
       *                              <uses> fragB1                               <uses> fragC1
       *                                                                          <uses> ExtB:fragB1
       * ---------------------------------------------------------------------------------------------------------------
       *  When Page C1 renders the fragments and their baseUrls must be the following:
       *    fragC1: vx/ExtC
       *    fragB1: vx/ExtB
       *    fragB2: vx/ExtB
       *    fragA1: vx/ExtA
       *
       *  When Page B1 renders the fragments and their baseUrl must be the following:
       *    fragB1: vx/ExtB
       *    fragB2: vx/ExtB
       *    fragA1: vx/ExtA
       *
       * Note: the presence of a prefix in the name prop of a fragment CCA has a definite bearing on the location from
       * where the fragment is loaded. Example pageC1, explicitly refers to 'ExtB:fragB1'
       * The absence of a prefix in name cannot be automatically assumed to be the calling extension's id. Example,
       * pageC1 ends up loading extB's fragB2 indirectly, as it's referenced by ExtB:fragB1. Here fragB2 must be
       * loaded from ExtB and not ExtC even though prefix is absent.
       *
       * @return {*}
       */
      /**
       * tracks callingContext for a fragment. Contains 3 properties
       *  - sourceExtension - the extension of the page that references the fragment, directly or transitively
       *  - parentExtension - the originating extension that references the fragment
       *  - owningExtension - the extension that contains fragment definition

       * @type {Object}
       */
      this.callingContext = undefined;

      const getOwningExtensionForId = (eid) => {
        let finalEid = eid;
        // check to see if we are referencing a global fragment
        if (finalEid === Constants.ExtensionNamespaces.BASE) {
          return Container.createEmptyExtension();
        }
        if (!finalEid) {
          // when an extensionId is not provided either the fragment is used and defined in the current extension or the
          // fragment is used indirectly by another fragment that itself was called from another extension. For
          // either case using the owningExtension's id should work
          // Example ExtA defines 2 fragments - F1 and F2. F1 references F2 from its F1-fragment.html as 'F2'
          // ExtB has a new-page.html that references F1 using 'extA:F1'. Even though F2 is used only by F1 the
          // extension to locate F2 is the current (owning) extension and not the parentExtension
          // F2 need not itself be marked referenceable as the use of F2 in F1 is an implementation detail.
          if (this.parent && this.parent.callingContext) {
            const pcc = this.parent.callingContext;
            finalEid = pcc.owningExtension.id;
          }
        }
        // in some cases global scope may not be available, so grab the extension registry from this.application.
        // For example layout does not expose global scope resolver, so a fragment inside layout will not have it.
        // But all containers have access to application
        const globalScope = (this.scopeResolver && this.scopeResolver[Constants.GLOBAL_PREFIX]) || this.application;
        const extensionObject = globalScope.application.extensionRegistry
          && globalScope.application.extensionRegistry.extensions;
        if (extensionObject) {
          return extensionObject[finalEid];
        }
        return undefined;
      };

      // source extension of a fragment inside a fragment is the extension of the page that includes the fragment
      let sourceExtension;
      if (parent.getCallingContext) {
        sourceExtension = parent.getCallingContext().sourceExtension;
      } else {
        sourceExtension = parent.extension;
      }

      this.callingContext = {
        sourceExtension,
        parentExtension: parent.extension,
        owningExtension: getOwningExtensionForId(this.fragmentExtensionId) || parent.extension,
      };

      // define other properties
      Object.defineProperties(this, {
        package: {
          value: this.getPackage(parent),
          enumerable: true,
        },
      });
    }

    get resourceLoc() {
      const resourcePrefix = (this.callingContext.owningExtension.id === Constants.ExtensionNamespaces.BASE)
        ? '' : `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;

      return `${resourcePrefix}${this.path}`;
    }

    /**
     * return the right resource folder to locate CSS defined in fragment
     * @returns {*}
     */
    getPackageCssResourceFolder() {
      if (this.callingContext.owningExtension.id === Constants.ExtensionNamespaces.BASE) {
        // for fragment in unified app the resource folder is at the root of the app
        return '';
      }
      return super.getPackageCssResourceFolder();
    }

    getPackageCssResourceUrl() {
      if (this.callingContext.owningExtension.id === Constants.ExtensionNamespaces.BASE) {
        // for fragment in unified app the (package) resource url is the (base) url of unified app
        return requirejs.toUrl('');
      }
      return super.getPackageCssResourceUrl();
    }

    /**
     * extension for fragment is always the extension where the fragment is defined, not where it's referenced from
     * @type {Object}
     */
    get extension() {
      return this.callingContext.owningExtension;
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * packageFragment we want to use the same event prefix as fragment
     *
     * @type {string}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'fragment';
    }

    /**
     * returns the package appUI that this fragment is used in
     * @param parent
     * @return {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getPackage(parent) {
      return parent && parent.package;
    }

    getCallingContext() {
      return this.callingContext;
    }

    allowsEventPropagation(type, eventDef) {
      const cc = this.callingContext;
      const calledFromAnotherExtension = this.fragmentExtensionId && cc && cc.parentExtension !== cc.owningExtension;
      const baseValue = super.allowsEventPropagation(type, eventDef);

      switch (type) {
        case Constants.EventPropagationBehaviors.CONTAINER:
          // event can bubble up to container if the type requested matches the behavior and event is declared an
          // interface
          // a fragment that is referenced from another extension allows event to propagate to container if the event
          // is declared and interface.
          if (baseValue && calledFromAnotherExtension && !eventDef.isInterface) {
            this.log.warn('Fragment Event', eventDef.name, 'is not an interface event and so cannot be dispatched to',
              'the outer container as a result. Check your configuration');
            return false;
          }
          return baseValue;
        default:
          return baseValue;
      }
    }

    /**
     * A fragment that was itself created by a package page can also create another fragment that is a type of this
     * class
     * @return {PackageFragment}
     * @constructor
     */
    static get FragmentClass() {
      return PackageFragment;
    }

    /**
     * If current fragment is defined in a different extension from the source extension (page) that references it,
     * check if access is allowed. If it's not allowed return false; all other cases return true.
     * @param descriptor
     * @return {boolean|*}
     */
    checkFragmentAccess(descriptor) {
      const { referenceable } = descriptor;
      const cc = this.callingContext;
      // only the fragment that is referenced directly is checked for access. Any fragment it internally references
      // is not checked for access as the local references are considered an implementation detail.
      // Example ExtA defines 2 fragments - F1 and F2. F1 references F2 from its F1-fragment.html as name: 'F2'
      // ExtB has a new-page.html that references F1 using name: 'extA:F1'. Even though F2 is used by F1, only
      // F1 must have this property set since ExtB just references F1 directly.
      const calledFromAnotherExtension = this.fragmentExtensionId && cc && cc.parentExtension !== cc.owningExtension;
      if (calledFromAnotherExtension && referenceable !== REFERENCEABLE.EXTENSION) {
        return false;
      }
      return super.checkFragmentAccess(descriptor);
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        const resLoc = this.resourceLoc;
        // if an extension prefix is provided we use it to check if file exists, but if no prefix is set then we
        // has to use the extension that this fragment belongs to. See example in determineBaseUrl
        this.extension.fileExists(`${resLoc}${this.fullName}.json`, this.extension.id);
        return super.descriptorLoader(resourceLocator);
      });
    }

    functionsLoader(resourceLocator) {
      const resLoc = this.resourceLoc;
      return Promise.resolve().then(() => {
        // if an extension prefix is provided we use it to check if file exists, but if no prefix is set then we
        // has to use the extension that this fragment belongs to. See example in determineBaseUrl
        this.extension.fileExists(`${resLoc}${this.fullName}.js`, this.extension.id);
        return super.functionsLoader(resourceLocator);
      });
    }

    templateLoader(resourceLocator) {
      const resLoc = this.resourceLoc;
      return Promise.resolve().then(() => {
        // if an extension prefix is provided we use it to check if file exists, but if no prefix is set then we
        // has to use the extension that this fragment belongs to. See example in determineBaseUrl
        this.extension.fileExists(`${resLoc}${this.fullName}.html`, this.extension.id);
        return super.templateLoader(resourceLocator);
      });
    }

    /**
     * Returns the name of the artifact
     * @returns {string}
     */
    get fullName() {
      // resourceName always uses fragmentName
      return `${this.resourceName}-fragment`;
    }

    /**
     * Returns a scope resolver map where keys are scope names ("global" / "fragment")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const scopesToInclude = {};
      const parentMap = (this.parent && this.parent.getScopeResolverMap()) || {};
      if (parentMap[Constants.GLOBAL_PREFIX]) {
        scopesToInclude[Constants.GLOBAL_PREFIX] = parentMap[Constants.GLOBAL_PREFIX];
      }
      return Object.assign({ [Constants.FRAGMENT_PREFIX]: this }, scopesToInclude);
    }
  }

  return PackageFragment;
});

