'use strict';

define('vb/extensions/dynamic/private/types/bridges/helperMetadataProviderFactoryBridge',[
  'vb/extensions/dynamic/private/types/bridges/metadataProviderFactoryBridge',
  'vb/private/utils',
], (MetadataProviderFactoryBridge, Utils) => {
  /**
   *
   */
  class HelperMetadataProviderFactoryBridge extends MetadataProviderFactoryBridge {
    constructor() {
      super();

      // used for collection broadcast properties (for DT) when the provider hasn't been created yet.
      this.propertyQueue = [];
    }

    /**
     *
     * This class is the factory bridge that creates the "vbHelper",
     * which is passed to the JET metadata providers.
     *
     * This Helper also uses
     * @param options
     * @returns {*}
     */
    translateOptions(options) {
      let newOptions;

      return Utils.getRuntimeEnvironment()
        .then((re) => {
          this.runtimeEnv = re;
          return super.translateOptions(options);
        })
        .then((opts) => {
          newOptions = opts;
          return this.getHelper(newOptions);
        })
        .then((helper) => {
          // Keep a reference of the helper class so we can clean up scope when
          // the metadata variable is disposed.
          this.helper = helper;
          newOptions.vbHelper = helper;

          helper.addListener((name, value) => {
            if (this.provider) {
              this.runtimeEnv.annotate(this.provider, name, value);
            } else {
              this.propertyQueue.push({ name, value });
            }
          });

          return newOptions;
        });
    }

    /**
     * called from createMetadataProvider().
     *
     * @params options the previous result of translateOptions().
     * @params options.factory optional, path to the JET MetadataProviderFactory.
     *         if not set, uses 'oj-dynamic/providers/JsonMetadataProviderFactory'.
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    getDelegatePath(options) {
      if (options && options.factory) {
        return options.factory;
      }
      // default
      return 'oj-dynamic/providers/JsonMetadataProviderFactory';
    }

    /**
     * create the "vbHelper".
     *
     * Creates a "vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper" by default.
     *
     * Can be configured using the optional "options.helper.class", but this is not documented.
     *
     * This method may be overridden by subclasses.
     *
     * @param options the previous result of translateOptions().
     * @param options.helper {class: {string} } optional, can specify the helper module path.
     */
    // eslint-disable-next-line class-methods-use-this
    getHelper(options) {
      return Promise.resolve()
        .then(() => {
          if (options && options.helper && options.helper.class) {
            return Utils.getResource(options.helper.class)
              .then((Helper) => Helper.get(options, options.context, options.container));
          }
          // for the base implementation, use a BaseMetadataProviderHelper
          return Utils
            .getResource('vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper')
            .then((HelperClass) => HelperClass.get(options, options.context, options.container));
        });
    }

    createMetadataProvider(options) {
      return super.createMetadataProvider(options)
        .then((provider) => {
          // we only kep this so we can expose properties on it for DT
          this.provider = provider;

          if (this.propertyQueue.length) {
            this.propertyQueue.forEach((queued) => {
              this.runtimeEnv.annotate(provider, queued.name, queued.value);
            });
            this.propertyQueue = [];
          }

          return provider;
        });
    }

    /**
     * Called by the AbstractMetadataProviderDescriptor when the variable is disposed
     */
    dispose() {
      // Call dispose on the MetadataProviderHelper to clean up the layout container
      if (this.helper) {
        this.helper.dispose();
      }

      super.dispose();
    }
  }

  return HelperMetadataProviderFactoryBridge;
});

